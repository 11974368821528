import React from 'react';
import { UniversalLink } from '@plone/volto/components';
import './styles.less';

function scrollToRow({ id_element, id_wrapper }) {
  if (typeof document === 'undefined') return


  const tableContainer = document.getElementById(id_wrapper);
  const targetRow = document.getElementById(id_element);

  if (targetRow && tableContainer) {
    // Position of the target row relative to the table container top
    const targetTop = targetRow.offsetTop - tableContainer.offsetTop;

    // Scroll the table container until the target row is at the top
    tableContainer.scrollTo({
      top: targetTop,
      behavior: 'smooth'
    });
  }

}

export const LinkElement = ({ attributes, children, element, mode }) => {
  // TODO: handle title on internal links
  let url = element.url;
  const { link } = element.data || {};

  const internal_link = link?.internal?.internal_link?.[0]?.['@id'];
  const external_link = link?.external?.external_link;
  const email = link?.email;
  const inPlaceScroll = link?.inPlaceScroll;

  const href = email
    ? `mailto:${email.email_address}${email.email_subject ? `?subject=${email.email_subject}` : ''
    }`
    : external_link || internal_link || url;

  const { title } = element?.data || {};

  return mode === 'view' ? (
    inPlaceScroll?.id_wrapper && inPlaceScroll?.id_element ?
      <a onClick={() => scrollToRow(inPlaceScroll)}>{children}</a>
      : <>
        <UniversalLink
          href={href || '#'}
          openLinkInNewTab={link?.external?.target === '_blank'}
          title={title}
        >
          {children}
        </UniversalLink>
      </>
  ) : (
    <span {...attributes} className="slate-editor-link">
      {children}
    </span>
  );
};
