import React from "react";

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


import { SearchAppContext } from '@directives/context'
// import { useNotification } from 'hooks';

import { AppliedFilters } from '.'

export default function NoResult() {

    const dispatch = React.useContext(SearchAppContext)
    // const { addNotification } = useNotification()


    const clearAll = () => {
        dispatch({ type: 'RESET', function: 'ALL' })
        // addNotification('success', 'All Settings reset.')
    }


    return (

        <Card sx={{ boxShadow: 'none' }}>
            <CardContent>
                <Typography variant="h5" component="div">
                    0 Results Found.
                </Typography>
                <Typography sx={{ mb: 1 }} color="text.secondary">
                    Your current search criteria did not match any documents in our database. Please adjust your <b>Search Criteria</b> or <b>Reset Search</b>.
                </Typography>

                <Typography sx={{ mb: 1 }} color="text.secondary">
                    <AppliedFilters />
                </Typography>

            </CardContent>
            <CardActions>
                <Button onClick={() => clearAll()} >Reset Search</Button>
            </CardActions>
        </Card>

    )
}