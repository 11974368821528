import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import { Button } from '@mui/material';
import * as DOMPurify from 'dompurify';

const useStyles = makeStyles({
  showMoreLessP: {
    lineHeight: '1.6rem'

  },
  showMoreLess: theme => ({
    cursor: "pointer",
    display: "inline",
    textDecoration: "none",
    color: theme.palette.primary.main,
  })

});


const ShowMoreText = ({ text, numberOfChars }) => {

  const theme = useTheme()
  const classes = useStyles(theme);

  const [isReadMore, setIsReadMore] = useState(true);
  const [data, setData] = useState(text)


  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const IsDataLarge = () => {

    if (text.split(" ").length > 25) return true
    else return false

  }

  const getHTMLText = (limit = false) => {

    let limitedText = text

    if (limit) limitedText = text.split(" ", 25).join(' ')

    return <span className={classes.showMoreLessP} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(limitedText) }} />
  }

  function showMoreLessToggle() {
    return (
      <>
        {(isReadMore && text) ? getHTMLText(true) : getHTMLText()}
        <span onClick={toggleReadMore} >
          <Button
            sx={{ fontSize: "0.8rem", padding: "0px 1px", textTransform: "none" }}
            className={classes.showMoreLess}
          >

            {isReadMore ? ("... read more") : (" read less")}

          </Button>
        </span>
      </>
    )
  }


  return (
    <p className="text" style={{ width: numberOfChars * 2 + "px" }}>
      {(data && IsDataLarge()) ? (showMoreLessToggle()) : getHTMLText()}
    </p>
  );
}


ShowMoreText.defaultProps = {
  numberOfChars: 150
}

export default ShowMoreText;