import React, { useContext, useEffect, useState, useRef } from 'react'
import { Form, Icon, Dropdown, Menu, Button } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';

import './HeaderSearchForm.less';

import { SearchAppContext } from '@directives/context';
import { getDefaultQuery, serialize } from '@directives/context/SearchApp/helpers';

export const HeaderSearchForm = () => {

    const advancedFieldEl = useRef()
    const history = useHistory();
    const dispatch = useContext(SearchAppContext);

    const [query, setQuery] = useState(getDefaultQuery());

    const handleChange = (e, { name, value }) => setQuery(prevState => ({
        ...prevState,
        [name]: value
    }))

    const handleChangeAdvField = ({ name, value }, field, type, alias = null, short = null) => {
        // if (!value) return
        const incomingAdvancedField = { "name": name, "type": type, "field": field, "value": value };

        if (short) incomingAdvancedField['short'] = short

        if (alias) incomingAdvancedField['alias'] = alias
        else incomingAdvancedField['alias'] = field

        const index = query.advancedFields.findIndex(advancedField => advancedField.name === incomingAdvancedField.name);
        if (index === -1) {
            if (incomingAdvancedField.value !== "") {
                setQuery(prevQuery => ({
                    ...prevQuery,
                    advancedFields: [...prevQuery.advancedFields, incomingAdvancedField]
                }));
            }
        } else {
            setQuery(prevQuery => {
                const updatedAdvancedFields = [...prevQuery.advancedFields];
                if (incomingAdvancedField.value !== "") {
                    updatedAdvancedFields[index] = { ...prevQuery.advancedFields[index], value: incomingAdvancedField.value };
                } else {
                    updatedAdvancedFields.splice(index, 1);
                }
                return {
                    ...prevQuery,
                    advancedFields: updatedAdvancedFields
                };
            });
        }
    }

    useEffect(() => {
        const currSearchParams = dispatch({ type: 'GET', field: 'SEARCH_PARAMS' })
        if (!currSearchParams.data) return

        setQuery(currSearchParams.data);

    }, [dispatch({ type: 'GET', field: 'SEARCH_PARAMS' }).data])


    useEffect(() => {

        if (!advancedFieldEl.current) return

        // advancedFieldEl.current.ref.current.addEventListener("keydown", (event) => e.stopPropagation())

    }, [advancedFieldEl])


    const searchApp = () => {

        dispatch({ type: 'SET', function: 'QUERY', query: query, overwriteLoadingErrorState: true })

        if (history.location.pathname === '/searchapp') {
            // history.replace(`/searchapp?${serialize(query)}`);
        } else {
            history.push(`/searchapp`);
        }
    }


    return (
        <Form
            onSubmit={(e) => { e.preventDefault(); searchApp() }}
        >
            <Form.Group>
                <Form.Field width={12}>
                    <Menu className='main-search-box' style={{ alignItems: 'center', borderRadius: 0 }}>
                        <Form.Field width={1} className='search-icon-btn'>
                            <Form.Button basic circular onclick={searchApp} icon={<Icon name='search' />} />
                        </Form.Field>
                        <Form.Field width={10}>
                            <Form.Input
                                placeholder='Search documents...'
                                name="SearchableText"
                                value={query['SearchableText'] === 'getall' ? '' : query['SearchableText']}
                                onChange={handleChange}
                            />
                        </Form.Field>
                        <Form.Field width={6} style={{ padding: '0', height: "60px", display: "flex" }}>
                            <Dropdown
                                direction='left'
                                className="advanced-options"
                                text={<>
                                    <span>
                                        <>{query.DirStatus === 'Archive' && 'archived'}</>
                                        <>{query.DirStatus === 'Current' && 'current'}</>
                                        <>{query.DirStatus === 'allStatus' && 'entire site'}</>
                                    </span>
                                    |
                                    <span className={`${query.WhichSite}`}>{query.WhichSite}</span>
                                </>}
                                ref={advancedFieldEl}
                            >
                                <Dropdown.Menu
                                    onClick={e => e.stopPropagation()}
                                    onKeyDown={(e) => {
                                        e.keyCode === 32 ? e.stopPropagation() : null;
                                        e.keyCode === 13 ? searchApp() : null;
                                    }}
                                >
                                    {operatorAdvancedSearch()}
                                    {statusAdvancedSearch()}
                                    {OrgAdvancedSearach()}
                                    <Dropdown.Divider />

                                    <Dropdown.Item>
                                        <Form.Field>
                                            <input
                                                name='advancedfield_directives_title'
                                                value={query?.advancedFields?.find(advancedField => advancedField.name === 'advancedfield_directives_title')?.value || ""}
                                                onChange={(e) => handleChangeAdvField(e.target, 'Title', 'text')}
                                                placeholder="search in document Title"
                                            />
                                        </Form.Field>
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                        <Form.Field>
                                            <input
                                                name='advancedfield_directives_description'
                                                value={query?.advancedFields?.find(advancedField => advancedField.name === 'advancedfield_directives_description')?.value || ""}
                                                onChange={(e) => handleChangeAdvField(e.target, 'Description', 'text')}
                                                placeholder="search in document Description"
                                            />
                                        </Form.Field>
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                        <Form.Field>
                                            <textarea
                                                name='advancedfield_directives_pdftext'
                                                value={query?.advancedFields?.find(advancedField => advancedField.name === 'advancedfield_directives_pdftext')?.value || ""}
                                                onChange={(e) => handleChangeAdvField(e.target, 'PDFText', 'text', 'Full Text')}
                                                placeholder="search in document Text"

                                                rows="4"
                                            />
                                        </Form.Field>
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                        <Form.Field>
                                            <label>Approved Date</label>
                                            <div className='daterange'>
                                                <div>
                                                    <input
                                                        type="date"
                                                        placeholder="From"
                                                        class="form-control"
                                                        name="advancedfield_directives_approvedDate_start"
                                                        field="approvedDate"
                                                        value={query?.advancedFields?.find(advancedField => advancedField.name === 'advancedfield_directives_approvedDate_start')?.value || ""}
                                                        onChange={(e) => handleChangeAdvField(e.target, 'approvedDate', 'date', "Approved Date")}
                                                    />
                                                </div>
                                                <div>&#8596;</div>
                                                <div>
                                                    <input
                                                        type="date"
                                                        placeholder="To"
                                                        class="form-control"
                                                        name="advancedfield_directives_approvedDate_end"
                                                        field="approvedDate"
                                                        value={query?.advancedFields?.find(advancedField => advancedField.name === 'advancedfield_directives_approvedDate_end')?.value || ""}
                                                        onChange={(e) => handleChangeAdvField(e.target, 'approvedDate', 'date', "Approved Date")}
                                                    />
                                                </div>
                                            </div>
                                        </Form.Field>
                                    </Dropdown.Item>
                                    <Button type="submit" size="large" className="button-submit">
                                        Show Results
                                    </Button>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Form.Field>
                    </Menu>
                </Form.Field>
                <Form.Field basic width={1} className="advanced-search">
                    <Button basic inverted type="submit" size="large">
                        SEARCH
                    </Button>
                </Form.Field>
            </Form.Group>
        </Form>
    )

    function OrgAdvancedSearach() {
        return <Dropdown.Item>
            <Dropdown.Text content="Sources" />
            <div className='options'>
                <Button
                    content="All Sites"
                    name="WhichSite"
                    value="allSites"
                    onClick={handleChange}
                    active={query.WhichSite === 'allSites' ? true : false} />

                <Button
                    content="Directives"
                    name="WhichSite"
                    value="directives"
                    onClick={handleChange}
                    active={query.WhichSite?.toLowerCase() === 'directives' ? true : false} />
                <Button
                    content="Standards"
                    name="WhichSite"
                    value="standards"
                    onClick={handleChange}
                    active={query.WhichSite?.toLowerCase() === 'standards' ? true : false} />
                <Button
                    content="NNSA"
                    name="WhichSite"
                    value="nnsa"
                    onClick={handleChange}
                    active={query.WhichSite?.toLowerCase() === 'nnsa' ? true : false} />
            </div>
        </Dropdown.Item>;
    }

    function statusAdvancedSearch() {
        return <Dropdown.Item>
            <Dropdown.Text content="Status" />
            <div className='options'>
                <Button
                    name="DirStatus"
                    value="allStatus"
                    onClick={handleChange}
                    content="Entire Site"
                    active={query.DirStatus === 'allStatus' ? true : false} />
                <Button
                    name="DirStatus"
                    value="Current"
                    onClick={handleChange}
                    content="Current"
                    active={query.DirStatus?.toLowerCase() === 'current' ? true : false} />
                <Button
                    name="DirStatus"
                    value="Archive"
                    onClick={handleChange}
                    content="Archived"
                    active={query.DirStatus?.toLowerCase() === 'archive' ? true : false} />
            </div>
        </Dropdown.Item>;
    }

    function operatorAdvancedSearch() {
        return <Dropdown.Item>
            <Dropdown.Text content="Match" />
            <div className='options'>
                <Button
                    name="operator"
                    value="AND"
                    onClick={handleChange}
                    content="All Words"
                    active={query.operator?.toLowerCase() === 'and' ? true : false} />
                <Button
                    name="operator"
                    value="OR"
                    onClick={handleChange}
                    content="Any Words"
                    active={query.operator?.toLowerCase() === 'or' ? true : false} />
            </div>
        </Dropdown.Item>;
    }

}