import React from 'react';
import { Container, Header, Segment, Loader, List, Grid } from 'semantic-ui-react'
import { Api, flattenToAppURL, isInternalURL } from '@plone/volto/helpers';
import { UniversalLink } from '@plone/volto/components';

const API = new Api();

const getFieldRelationalData = async (context) => {

    let data = await API.post('/@getRelationalData', {
        data: {
            'UID': context['UID']
        }
    });

    return data
}

export default ({ content }) => {

    const [relations, setRelations] = React.useState(null)

    React.useEffect(async () => {
        const data = await getFieldRelationalData(content);
        setTimeout(() => {
            setRelations(data)
        }, 50);

    }, []);

    const DisplaySource = (source) => {

        const extraData = relations.additionalSourcesInfo.find(d => d.uid === source['UID'])

        return (
            <>
                <List.Header>
                    <UniversalLink href={source['@id']}>
                        {extraData.docid}, {source.title}
                    </UniversalLink>
                </List.Header>
                <List.Description style={{ display: 'flex', flexDirection: 'column' }}>
                    <span><b>Status:</b> {extraData.status}</span>
                    <span><b>Approved Date:</b> {extraData.approvedDate}</span>
                </List.Description>
            </>
        )
    }

    const DisplayDefinitionSources = (definition, sources, n) => {

        if (sources === null || sources?.length == 0) {
            return <></>
        }

        return <Segment>
            <div>
                <h3>Definition</h3>
                <div style={{ marginBottom: '10px' }} dangerouslySetInnerHTML={{ __html: definition.data }}></div>
            </div>
            <div>{
                relations === null ? <Loader active inline /> :
                    <List celled>
                        {sources?.length > 0 && sources.map(source => <List.Item style={{ padding: '15px' }}>{DisplaySource(source)}</List.Item>)}
                    </List>
            }</div>
        </Segment>
    }

    const SimilarItems = () => {

        return (
            <>
                <h3>Similar Terms</h3>
                {content?.similarTerms?.filter(item => item !== null && item.hasOwnProperty('@id') && item.hasOwnProperty('title'))?.length > 0 ? (
                    <List bulleted>
                        {content.similarTerms.map(item => <List.Item>
                            <UniversalLink href={item['@id']}>
                                {item.title}
                            </UniversalLink>
                        </List.Item>
                        )}
                    </List>)
                    : <>No items have been linked to this term.</>}
            </>
        )
    }

    const categories = () => {
        return (
            <>
                <h3>Categories</h3>
                {content?.categories?.length > 0 ? (<List bulleted>
                    {content.categories.map(item => <List.Item>
                        {item.title}
                    </List.Item>
                    )}
                </List>)
                    : <>No items have been linked to this term.</>
                }
            </>
        )
    }

    return (<Container>
        <Header as="h1">{content.title}</Header>
        {content?.def1 && DisplayDefinitionSources(content.def1, content.sourceLink1, 1)}
        {content?.def2 && DisplayDefinitionSources(content.def2, content.sourceLink2, 2)}
        {content?.def3 && DisplayDefinitionSources(content.def3, content.sourceLink3, 3)}
        {content?.def4 && DisplayDefinitionSources(content.def4, content.sourceLink4, 4)}
        {content?.def5 && DisplayDefinitionSources(content.def5, content.sourceLink5, 5)}
        <Segment>
            {SimilarItems()}
            {categories()}
        </Segment>
    </Container>
    )
}