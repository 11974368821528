import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';

import TextField from '@mui/material/TextField';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Select from '@mui/material/Select';
import { Paper } from '@mui/material';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';

import MenuItem from '@mui/material/MenuItem';

import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import ListItemIcon from '@mui/material/ListItemIcon';

import { PopupSettingsMenu } from '@directives/helpers';

import { SearchAppContext } from '@directives/context'

import { useViewport } from '@directives/hooks';
import { useTheme } from '@mui/material/styles';


import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import FormControl from '@mui/material/FormControl';


const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow: 'none'
}));

const MenuItemCustom = styled(ToggleButton)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    display: "flex",
    justifyContent: "left",
    padding: "8px 16px",

}));

const useStyles = makeStyles({
    root: {
        display: "flex",
        justifyContent: 'space-between',
        alignItems: 'center',
        background: 'inherit',
    }

})

export default function Actions({ searchView, setSearchView }) {

    const dispatch = React.useContext(SearchAppContext)

    // const { addNotification } = useNotification()

    const { width } = useViewport()
    const theme = useTheme()

    const [actions, setActions] = React.useState(null)
    const [pageError, setPageError] = React.useState(false)

    const classes = useStyles()

    React.useEffect(() => {
        const incAction = dispatch({ type: 'GET', field: 'RESULT_INFO' });
        if (incAction?.data === actions?.data) return

        setActions(incAction);

    }, [])

    const currentPage = actions?.data?.currentPage ?? 0
    const pageRange = actions?.data?.pageRange ?? 0
    const perPage = actions?.data?.perPage ?? 0

    // Popup Menu Actions
    const clearSorting = () => {
        dispatch({ type: 'RESET', function: 'SORT' })
        // addNotification('success', 'Sorting reset.')

    }

    const clearFilters = () => {
        dispatch({ type: 'RESET', function: 'FQS' })
        // addNotification('success', 'All Settings reset.')
    }

    const handleResultViewChange = (e, value) => {
        if (value) setSearchView(value)
    }

    // Main Actions
    const setPage = (action) => {

        switch (action) {

            case 'INC':
                setPageError(false)
                let nextPage = 1
                if (currentPage + 1 <= pageRange) {
                    nextPage = currentPage + 1
                    dispatch({ type: 'SET', function: 'PAGE', page: nextPage })
                } else {
                    setPageError(true)
                }
                break;

            case 'DEC':
                setPageError(false)
                let prevPage = 1
                if (currentPage - 1 >= 1) {
                    prevPage = currentPage - 1
                    dispatch({ type: 'SET', function: 'PAGE', page: prevPage })
                } else {
                    setPageError(true)
                }
                break;

            default:

                if (0 < action && action <= pageRange) {
                    dispatch({ type: 'SET', function: 'PAGE', page: action })
                } else {
                    setPageError(true)
                }
        }
    }

    const setPerPage = (perpage) => {
        dispatch({ type: 'SET', function: 'PER_PAGE', perpage: perpage })
    }

    const MainActions = () => {
        return (
            <Stack
                direction={{ xs: 'column', sm: 'row' }}
                divider={<Divider orientation="vertical" flexItem />}
                spacing={2}
            >
                <Item>
                    <Typography variant="caption">
                        PAGE
                    </Typography>
                    <IconButton
                        onClick={() => setPage('DEC')}
                        aria-label="Last result page">
                        <NavigateBeforeIcon />
                    </IconButton>
                    <TextField
                        style={{ maxWidth: "5rem" }}
                        error={pageError}
                        id="outlined-basic"
                        label={currentPage + "/" + pageRange}
                        variant="outlined"
                        size="small"

                        helperText={(pageError) ? currentPage + "/" + pageRange : ""}
                        onKeyPress={(ev) => {
                            if (ev.key === 'Enter') {
                                setPage(ev.target.value)
                            }
                        }}
                        inputProps={{
                            min: 1,
                            max: pageRange,
                        }}

                    />
                    <IconButton
                        onClick={() => setPage('INC')}
                        aria-label="Next result page">
                        <NavigateNextIcon />
                    </IconButton>
                </Item>

                <Item
                    sx={{
                        "& fieldset legend": {
                            display: "none",
                        },
                        "& fieldset": {
                            top: 0
                        },
                    }}
                >
                    <Typography variant="caption" style={{ paddingRight: '15px' }}>
                        RESULT PER PAGE
                    </Typography>


                    <Select
                        autoWidth={true}
                        size="small"
                        onChange={(event) => setPerPage(event.target.value)}
                        value={perPage}
                    >
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={75}>75</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                    </Select>

                </Item>
            </Stack>
        )
    }

    const SharedActions = () => {
        return (
            <>

                <Typography p={1}>RESULT VIEW</Typography>

                <ToggleButtonGroup
                    orientation="vertical"
                    value={searchView}
                    exclusive
                    size="small"
                    color="primary"
                    onChange={handleResultViewChange}
                    style={{ width: "100%" }}

                >

                    <MenuItemCustom value="AutoView" aria-label="search table view">

                        <ListItemIcon style={{ minWidth: "36px" }} >
                            <ViewListIcon />
                        </ListItemIcon>

                        <Typography style={{ color: "black" }}> Auto </Typography>
                    </MenuItemCustom>

                    <MenuItemCustom value="TableView" aria-label="search table view">

                        <ListItemIcon style={{ minWidth: "36px" }} >
                            <ViewListIcon />
                        </ListItemIcon>

                        <Typography style={{ color: "black" }}> Table View </Typography>
                    </MenuItemCustom>


                    <MenuItemCustom value="CardView" aria-label="search card view">
                        <ListItemIcon style={{ minWidth: "36px" }}>
                            <ViewModuleIcon />
                        </ListItemIcon>

                        <Typography style={{ color: "black" }}> Card View </Typography>
                    </MenuItemCustom>

                </ToggleButtonGroup>




                <Typography p={1}>RESET</Typography>
                <Divider mb={1} />

                <MenuItem
                    onClick={() => clearSorting()}
                >
                    <ListItemIcon>
                        <SortByAlphaIcon />
                    </ListItemIcon>
                    Reset Sorting
                </MenuItem>

                <MenuItem
                    onClick={() => clearFilters()}
                >
                    <ListItemIcon>
                        <FilterListOffIcon />
                    </ListItemIcon>
                    Reset Filters
                </MenuItem>

            </>
        )
    }


    const PopupSettingsMenuActions = () => {
        return (
            <Item>
                <PopupSettingsMenu>
                    <SharedActions />
                </PopupSettingsMenu>
            </Item>
        )
    }

    const MobileActions = () => {
        return (
            <Item>
                <PopupSettingsMenu>

                    <MenuItem>
                        {MainActions()}
                    </MenuItem>

                    <Divider mb={1} />

                    <SharedActions />

                </PopupSettingsMenu>
            </Item>
        )
    }


    // return React.useMemo(() => {
    return (
        <div className={classes.root}>
            {
                (theme.breakpoints.values.md > width) ? (
                    <>
                        <div></div>
                        {MobileActions()}
                    </>
                )
                    :
                    (
                        <>
                            {MainActions()}
                            {PopupSettingsMenuActions()}
                        </>
                    )
            }
        </div>
    );
    // }, [actions, searchView, pageError])

}