/**
 * RequestPasswordReset component.
 * @module components/theme/RequestPasswordReset/RequestPasswordReset
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { Helmet } from '@plone/volto/helpers';
import { Container } from 'semantic-ui-react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';

import { Form } from '@plone/volto/components';
import { resetPassword } from '@plone/volto/actions';
import config from '@plone/volto/registry';

const messages = defineMessages({
    title: {
        id: 'box_forgot_password_option',
        defaultMessage: 'Forgot your password?',
    },
    description: {
        id: 'description_lost_password',
        defaultMessage:
            'Farooq'
    },
    default: {
        id: 'Default',
        defaultMessage: 'Default',
    },
    usernameTitle: {
        id: 'label_my_username_is',
        defaultMessage: 'Enter username',
    },
    emailTitle: {
        id: 'label_my_email_is',
        defaultMessage: 'My email is',
    },
    sendEmail: {
        id: 'Start password reset',
        defaultMessage: 'Start password reset',
    },
    usernameRequired: {
        id: 'Your usernaame is required for reset your password.',
        defaultMessage: 'Your username is required for reset your password.',
    },
    emailRequired: {
        id: 'Your email is required for reset your password.',
        defaultMessage: 'Your email is required for reset your password.',
    },
    passwordReset: {
        id: 'Password reset',
        defaultMessage: 'Password reset',
    },
});

/**
 * RequestPasswordReset class.
 * @class RequestPasswordReset
 * @extends Component
 */
class RequestPasswordReset extends Component {
    /**
     * Property types.
     * @property {Object} propTypes Property types.
     * @static
     */
    static propTypes = {
        loading: PropTypes.bool.isRequired,
        loaded: PropTypes.bool.isRequired,
        error: PropTypes.string,
        resetPassword: PropTypes.func.isRequired,
    };

    /**
     * Default properties.
     * @property {Object} defaultProps Default properties.
     * @static
     */
    static defaultProps = {
        error: null,
    };

    /**
     * Constructor
     * @method constructor
     * @param {Object} props Component properties
     * @constructs Controlpanel
     */
    constructor(props) {
        super(props);
        this.onCancel = this.onCancel.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.identifierField = config.settings.useEmailAsLogin
            ? 'email'
            : 'username';

        if (this.identifierField === 'email') {
            this.identifierTitle = this.props.intl.formatMessage(messages.emailTitle);
            this.identifierRequiredMessage = this.props.intl.formatMessage(
                messages.emailRequired,
            );
        } else {
            this.identifierTitle = 'Enter Username'
            this.identifierRequiredMessage = this.props.intl.formatMessage(
                messages.usernameRequired,
            );
        }

        this.state = {
            error: null,
            isSuccessful: false,
        };
    }

    /**
     * Component will receive props
     * @method componentWillReceiveProps
     * @param {Object} nextProps Next properties
     * @returns {undefined}
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.loading && nextProps.loaded) {
            this.setState({ isSuccessful: true });
        }
    }

    /**
     * Submit handler
     * @method onSubmit
     * @param {object} data Form data.
     * @param {object} event Form data.
     * @returns {undefined}
     */
    onSubmit(data) {
        if (data[this.identifierField]) {
            this.props.resetPassword(data[this.identifierField]);
            this.setState({
                error: null,
            });
        } else {
            this.setState({
                error: {
                    message: this.identifierRequiredMessage,
                },
            });
        }
    }

    /**
     * Cancel handler
     * @method onCancel
     * @returns {undefined}
     */
    onCancel() {
        this.props.history.goBack();
    }

    /**
     * Render method.
     * @method render
     * @returns {string} Markup for the component.
     */
    render() {
        if (this.state.isSuccessful) {
            return (
                <Container>
                    <h1 className="documentFirstHeading">
                        <FormattedMessage
                            id="heading_sent_password"
                            defaultMessage="Password reset confirmation sent"
                        />
                    </h1>
                    <p className="description">
                        <FormattedMessage
                            id="description_sent_password"
                            defaultMessage="Your password reset request has been mailed. It should arrive in your mailbox shortly. When you receive the message, visit the address it contains to reset your password."
                        />
                    </p>
                </Container>
            );
        }

        return (
            <div id="page-password-reset" >
                <Helmet title={this.props.intl.formatMessage(messages.passwordReset)} />
                <Container>
                    <Form
                        title={this.props.intl.formatMessage(messages.title)}
                        description='If you would like to reset your password, fill out the form below and we will send an email to the address associated with your username.'
                        onSubmit={this.onSubmit}
                        onCancel={this.onCancel}
                        error={this.state.error || this.props.error}
                        schema={{
                            fieldsets: [
                                {
                                    id: 'default',
                                    title: this.props.intl.formatMessage(messages.default),
                                    fields: [this.identifierField],
                                },
                            ],
                            properties: {
                                [this.identifierField]: {
                                    type: 'string',
                                    title: this.identifierTitle,
                                },
                            },
                            submitLabel: this.props.intl.formatMessage(messages.sendEmail),
                            required: [this.identifierField],
                        }}
                    />
                </Container>
            </div>
        );
    }
}

export default compose(
    withRouter,
    injectIntl,
    connect(
        (state) => ({
            loading: state.users.reset.loading,
            loaded: state.users.reset.loaded,
            error: state.users.reset.error,
        }),
        { resetPassword },
    ),
)(RequestPasswordReset);
