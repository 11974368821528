import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from "react-ga4";

const GA4_MEASUREMENT_ID = 'G-KCKVV1XCYP';
// const COOKIE_EXPIRES = 6 * 30 * 24 * 60 * 60;


ReactGA.initialize(GA4_MEASUREMENT_ID);
export default () => {
    let location = useLocation();
    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: location.pathname });
    }, [location]);
    return true;
}