
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { getCookieConsentInfos } from './helpers'
import { CookieConsent as ReactCookieConsent } from "react-cookie-consent";

import "./CookieConsent.less"

const ReactCookieConsentStyles = {
    background: 'rgb(36 36 36 / 94%)',
    alignItems: 'center',
    paddingInline: 'clamp(0px, 5vw, 100px)',
}

const CookieConsent = () => {
    const [shouldRender, setShouldRender] = useState(false);
    const cookieConsentInfos = useSelector(state => state.cookieConsentInfos.result);
    const dispatch = useDispatch();

    useEffect(() => {
        const hasCookieConsent = document.cookie.includes('cookieconsent=true');
        if (!hasCookieConsent) {
            dispatch(getCookieConsentInfos());
            setShouldRender(true);
        }
    }, [dispatch]);

    return shouldRender ? (
        <ReactCookieConsent
            location="bottom"
            buttonText="Accept"
            cookieName="directives-cookieconsent"
            style={ReactCookieConsentStyles}
            expires={150}
            disableButtonStyles={true}
        >
            <div className="CookieConsentContainer">
                <div dangerouslySetInnerHTML={{ __html: cookieConsentInfos?.cookie_consent_configuration?.title }} />
                <div dangerouslySetInnerHTML={{ __html: cookieConsentInfos?.cookie_consent_configuration?.body }} />
            </div>
        </ReactCookieConsent>
    ) : <></>
}

export default CookieConsent;