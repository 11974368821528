import React from "react";
import { Segment, Grid, Tab, Modal, Label, Container, Button, Message, Menu } from 'semantic-ui-react';
import { Api, flattenToAppURL, isInternalURL } from '@plone/volto/helpers';
import { Link, useHistory } from 'react-router-dom';
import { UniversalLink } from '@plone/volto/components';

import './TypesView.less'

import TableByType from "./TableByType"
import DagreGraph from 'dagre-d3-react'

import config from '@plone/volto/registry';


const API = new Api();
const convertBytes = bytes => `${(bytes < 1 << 10)
    ? bytes.toFixed(2) + 'B' : (bytes < 1 << 20)
        ? (bytes / (1 << 10)).toFixed(2) + 'KB' : (bytes < 1 << 30)
            ? (bytes / (1 << 20)).toFixed(2) + 'MB' : (bytes / (1 << 30)).toFixed(2) + 'GB'
    }`;


const getGraphData = async (context) => {

    let data = await API.post('/@getDocumentGraph', {
        data: {
            'UID': context['UID']
        }
    });

    return data
}

const getFieldRelationalData = async (context) => {

    let data = await API.post('/@getRelationalData', {
        data: {
            'UID': context['UID']
        }
    });

    return data
}

const TypesView = (props) => {

    const { settings } = config;
    const { content } = props;

    const history = useHistory();

    const [panes, setPanes] = React.useState([])
    const [relations, setRelations] = React.useState(null)
    const [graph, setGraph] = React.useState([])
    const [open, setOpen] = React.useState(false)
    const [tabsDefaultActiveIndex, SetTabsDefaultActiveIndex] = React.useState(-1)
    const [tabsActiveIndex, SetTabsActiveIndex] = React.useState(-1)


    React.useEffect(async () => {
        const data = await getGraphData(content);

        // ignore empty/small graphs
        if (!Array.isArray(data.nodes) || !Array.isArray(data.edges)) return
        else if (data.nodes.length < 2) return


        // data.edges.forEach(element => {
        //     element.config['labelpos'] = 'l'
        // });

        // add color to parent node
        let parent = data.nodes.find(node => node.mark)
        parent["config"] = {
            class: 'current-node'
        }

        setGraph(data)
    }, [])

    React.useEffect(async () => {
        const data = await getFieldRelationalData(content);

        setRelations(data)
    }, []);

    React.useEffect(() => {
        if (!relations) return

        let mainPanes = []
        let defaultIndex = -1

        relations.order.forEach((field, index) => {
            const data = relations.items[field]

            const isFieldDisabled = data.every(d => d?.value === null || Array.isArray(d?.value) && d.value.length === 0);

            if (!isFieldDisabled && defaultIndex === -1) {
                defaultIndex = index
            }

            mainPanes.push({
                menuItem: (
                    <Menu.Item key={field} disabled={isFieldDisabled} onClick={() => SetTabsActiveIndex(index)}>
                        {field}
                    </Menu.Item>
                ),
                render: () => RelationPaneel(field)
            })

        })

        setPanes(mainPanes)
        SetTabsDefaultActiveIndex(defaultIndex)

    }, [relations, open, graph])

    const navigateToDocument = (url) => {
        if (!url) return
        const path = flattenToAppURL(url);

        if (path === content?.short_name) return

        history.push(path)
    }

    const Graph = () => {

        return (
            <Modal
                className="documentgraph"
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                open={open}
                trigger={<Button id="show-graph" className={content.status?.title.toLowerCase()}>Show All</Button>}
            >
                <Modal.Header>{content?.title}</Modal.Header>
                <Modal.Content>
                    <h5>Scroll and Zoom for details. Click on Nodes to View Document.</h5>
                    <DagreGraph
                        // ref={svg}
                        nodes={graph.nodes ? graph.nodes : []}
                        links={graph.edges ? graph.edges : []}
                        config={{
                            rankdir: "TB",
                            labelpos: "c",
                        }}
                        width='100%'
                        height='500'
                        animate={1000}
                        shape='rect'
                        fitBoundaries
                        zoomable
                        onNodeClick={(e) => navigateToDocument(e?.original?.url)}
                    />
                    <div>
                        <svg className="svgbg">
                            <defs>
                                <filter x="-5%" y="-5%" width="110%" height="110%" id="svgbg">
                                    <feFlood flood-color="#ddd" result="bg" />
                                    <feMerge>
                                        <feMergeNode in="bg" />
                                        <feMergeNode in="SourceGraphic" />
                                    </feMerge>
                                </filter>
                            </defs>
                        </svg>
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='black' onClick={() => setOpen(false)}>
                        Close
                    </Button>
                </Modal.Actions>
            </Modal>
        )
    }

    const RelationPaneel = (field) => {
        let data = relations.items?.[field]
        data = data.filter(d => d?.value?.length > 0);

        if (data.length < 1) {
            return <></>
        }

        return (
            <Tab.Pane loading={data === undefined} attached={false} className={content.status?.title.toLowerCase()}>

                {field === 'history' && graph?.nodes?.length > 1 ? Graph() : <></>}
                <>
                    {
                        data ?
                            (data
                                .filter(data => data.value)
                                .map((data) => {
                                    return (
                                        data.value.length > 0 && <Segment basic>
                                            {data?.title !== '' && <h4>{data.title}:</h4>}
                                            {data.value.map(item => {
                                                const Component = item.url !== '' ? UniversalLink : 'div';

                                                return (
                                                    <Segment>
                                                        <Component
                                                            href={item?.url?.length > 0 ? settings.portals.updateURL(item.url) : ''}
                                                        >
                                                            <span>
                                                                {item?.docid ? `${item?.docid}, ` : ''}
                                                                {item?.title ? `${item?.title} ` : ''}
                                                                {item?.date ? `on ${item.date}` : ''}
                                                            </span>
                                                            <span>
                                                                {
                                                                    item?.review_state === 'private' ?
                                                                        <Label style={{ float: 'right' }} size='tiny' color='red'>
                                                                            Private
                                                                        </Label>
                                                                        : <></>
                                                                }
                                                            </span>
                                                        </Component>
                                                    </Segment>
                                                )
                                            })}
                                        </Segment>
                                    )
                                })
                            ) : (<></>)
                    }
                </>
            </Tab.Pane>
        )
    };

    return (
        <Container>
            <div id='PortalCustomContentType'>

                {
                    content.status ? <Segment className={'status ' + content.status?.title.toLowerCase()}>
                        {content.status?.title}
                    </Segment> : <></>
                }


                <Grid stackable className="main-content">
                    <Grid.Row columns={12} style={{ margin: 0, paddingBottom: 0 }}>
                        <Grid.Column width={12} >
                            <Grid.Row className='title'>
                                {content?.docid ? `${content?.docid}, ` : ''}
                                {content?.title}
                            </Grid.Row>
                            {
                                content?.subjects && content.subjects.length > 0 ? <Grid.Row>
                                    Functional areas: {content?.subjects.map((sub) => <span> {sub},</span>)}
                                </Grid.Row> : <></>
                            }

                            <Grid.Row>
                                {content?.description}
                            </Grid.Row>

                            <Grid.Row>
                                {content?.bodyText && (
                                    <div dangerouslySetInnerHTML={{ __html: content.bodyText.data }} />
                                )}
                            </Grid.Row>

                            <Grid.Row>
                                {
                                    content?.file?.download ? <Message style={{ padding: '1rem' }}>
                                        <a target="_blank" href={content['@id'] + "/@@images/file"}>
                                            {content?.file?.filename || 'Document'}
                                        </a>
                                        <span style={{ fontSize: "0.85em" }}>
                                            {content?.file?.size !== undefined && ` ${convertBytes(content?.file?.size)}`}
                                        </span>
                                    </Message> : <></>
                                }
                            </Grid.Row>

                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row columns={2} style={{ paddingTop: 0 }}>

                        <Grid.Column width={7}>
                            <div id="document-metadata">
                                <TableByType content={content} />
                            </div>

                        </Grid.Column>

                        <Grid.Column width={5} >
                            <div>
                                <Tab id="tabs" menu={{ attached: false, tabular: false }} panes={panes} activeIndex={tabsActiveIndex !== -1 ? tabsActiveIndex : tabsDefaultActiveIndex} />
                            </div>
                        </Grid.Column>

                    </Grid.Row>
                </Grid>
            </div>
        </Container>
    )
}

export default TypesView;
