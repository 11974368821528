import React, { useEffect } from "react"
import { Container } from 'semantic-ui-react';
import { defineMessages } from 'react-intl';
import { UniversalLink } from '@plone/volto/components';
import config from '@plone/volto/registry';


import "./ListingNewsVariation.less";


function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'short', day: '2-digit' };
    return date.toLocaleDateString('en-US', options);
}

export const ListingNewsVariationView = (props) => {

    const items = props.items
    const { settings } = config
    const prefix = `${settings?.prefixPath ?? ''}`

    return (<Container id="news-view-1">
        {items.map(item => {
            return (
                <div>
                    <UniversalLink href={item['@id']}>
                        {item.Title}
                    </UniversalLink>
                    <span>{formatDate(item?.CreationDate)}</span>
                </div>
            )
        })}
        <UniversalLink id="more-link" href={prefix + `/news`}>More..</UniversalLink>
    </Container>)
}

const messages = defineMessages({
    fields: {
        id: 'Search block',
        defaultMessage: 'Search block',
    }
})



export const ListingNewsVariation = {

    id: 'news-view-1',
    title: 'News View 1',
    template: ListingNewsVariationView,
}

