import * as React from 'react';
import debounce from 'lodash.debounce';


const WIDTH_CUT_OFF = 767;

const useViewport = () => {

  const [width, setWidth] = React.useState();
  const [height, setHeight] = React.useState();
  const [isMobile, setIsMobile] = React.useState();


  React.useEffect(() => {

    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
    // setIsMobile(window.innerWidth < WIDTH_CUT_OFF)

    const handleWindowResize = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    }

    window.addEventListener("resize", debounce(handleWindowResize, 1000));
    return () => window.removeEventListener("resize", handleWindowResize);

  }, []);


  // Return both the height and width
  return { width, height, isMobile };
}

export default useViewport