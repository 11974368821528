import React, { useContext, useEffect, useState } from 'react'
import { Modal, Button } from 'semantic-ui-react';

import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

export default ({ style, trigger, closeOnInnerModalClick = true, children, actions = false, ...props }) => {

    const [open, setOpen] = useState(false)

    return (
        <Modal
            style={style}
            trigger={trigger}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            onClick={() => closeOnInnerModalClick && setOpen(false)}
            closeOnDocumentClick={true}
            {...props}
        >
            <Modal.Header>
                <Button onClick={() => setOpen(false)}>
                    <CloseIcon />
                </Button>
            </Modal.Header>

            <Modal.Content scrolling style={{ maxHeight: 'calc(100vh - 6em)' }}>
                {children}
            </Modal.Content>
            {actions && <Modal.Actions>
                {actions}
            </Modal.Actions>}
        </Modal>
    )

}