import React from 'react';
import { Table } from 'semantic-ui-react';


const Row = (title, field, isDate = false) => {

    if (!field) return

    let displayField = field


    if (isDate) {
        try {
            const [year, month, day] = field.split('-');
            const date = new Date(year, month - 1, day); // Month is zero-based

            if (!isNaN(date)) {
                displayField = date.toLocaleDateString('en-US', {
                    month: 'short',
                    day: 'numeric',
                    year: 'numeric'
                });
            }
        } catch (e) { }
    }

    return (
        <Table.Row>
            <Table.Cell verticalAlign="top">
                {title}
            </Table.Cell>
            <Table.Cell verticalAlign="top">
                {displayField}
            </Table.Cell>
        </Table.Row>
    )
}

const DOETypeDirectives = (content) => {
    return (
        <Table>
            <Table.Body >
                {Row('ID:', content?.docid)}
                {content?.creators?.length > 0 && Row('Writer:', content?.creators?.[0])}
                {content.subjectareas?.length > 0 && Row('Subjects:', content.subjectareas[0].title)}
                {content.type?.length > 0 && Row('Subjects:', content.subjectareas[0].title)}
                {Row('Type:', content?.type.title)}
                {content?.opi?.length > 0 && Row('OPI:', content?.opi[0].title)}
                {Row('Status:', content?.status?.title)}
                {Row('Approved Date:', content?.approvedDate, true)}
                {Row('Last Update:', content?.updatedDate, true)}
                {Row('CRD:', content?.crd?.title)}
                {Row('Invoking Directive:', content?.invokingdirective?.title)}

            </Table.Body>
        </Table>
    )
}

const DOETypeDelegation = (content) => {
    return (
        <Table>
            <Table.Body >
                {Row('ID:', content?.docid)}
                {Row('Type:', content?.type?.title)}
                {content?.delegant && Row(
                    content?.type?.title === 'Designation' ? 'Designator:' : 'Delegant',
                    `${content?.delegant}, ${content?.delegantPosition || ''}`
                )}
                {Row(
                    content?.type?.title === 'Designation' ? 'Designee:' : 'Delegate',
                    `${content?.delegate ? content?.delegate + ', ' : ''} ${content?.delegatePosition || ''}`
                )}
                {Row('Status:', content?.status?.title)}
                {Row('Effective Update:', content?.effectiveDate, true)}
                {content?.updatedDate && Row('Last Update:', content?.updatedDate, true)}
                {Row('Re-delegation Allowed:', content?.reDelegationAllowed?.token)}
                {content?.reDelegationLim && Row('Re-delegation Limitations:', content?.reDelegationLim)}
                {content?.signatory && Row('Signatory', content?.signatory)}
                {content?.fean && Row('Federal Enterprise Architecture Number:', content?.fean)}
                {content?.authorities && Row('Authorities', content?.authorities)}


                {/* {Row('SLM Office:', content?.slm?.title || content?.slm[0]?.title)}
                {Row('OPI:', content?.OPI?.title || content?.OPI[0]?.title)}
                {Row('Issue Date:', content?.issueDate, true)}
                {Row('Last Update:', content?.updatedDate, true)}
                {Row('CRD:', content?.crd?.title)}
                {Row('DNFSB:', content?.dnfsb?.title)}
                {Row('CTA:', content?.cta?.title)}
                {Row('Misc. Comments:', content?.miscComments)} */}

            </Table.Body>
        </Table>
    )
}


const TableByType = ({ content }) => {

    const Type = content?.['@type'];

    if (Type === 'DOE.Directives.directive')
        return DOETypeDirectives(content)
    else if (Type === 'DOE.Directives.delegation')
        return DOETypeDelegation(content)

    return <></>
}

export default TableByType;