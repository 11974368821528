import React, { useState, useEffect } from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { makeStyles } from '@mui/styles';


const useStyles = makeStyles({
  root: {
    padding: "15px"
  },

  disabled: {
    pointerEvents: "none",
    opacity: "0.4"
  },

  selected: {
    color: "#02314B"
  }
});


export default function FilterItemsCheckbox({ name, items, addRemoveFilterItem, isFilterItemSelected, isUpdate, type }) {

  const classes = useStyles();

  const [count, setcount] = useState(0);

  const handleItemSelection = (valueItem) => {

    addRemoveFilterItem(valueItem);
  }

  const isSelected = (valueItem) => {

    let isActive = isFilterItemSelected(valueItem);
    return isActive;

  }


  return React.useMemo(() => {

    let temp = {}
    for (let i = 0; i < items.length; i = i + 2) {

      if (items[i + 1] > 0 || type === 'OR')
        temp = { ...temp, [items[i]]: items[i + 1] }
    }

    return (
      <div>
        {
          Object.keys(temp).map((item, i) => {
            if (type != "OR" && temp[item] < 1) return

            const valueItem = `${name}:("${item}")`
            const isItemSelected = isSelected(valueItem)
            const displayFiled = `${item} (${temp[item]})`;

            return (
              <List key={item + i} component="div" disablePadding>
                <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={() => handleItemSelection(valueItem)}

                >
                  <ListItemIcon>
                    {isItemSelected ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                  </ListItemIcon>
                  <ListItemText primary={displayFiled} style={{ wordBreak: "break-word" }} />
                </ListItemButton>
              </List>
            )
          })
        }
      </div>
    );

  }, [items])
}